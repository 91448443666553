import React from 'react';
import CookieLayout from '../components/CookieLayout';
import SEO from '../components/Seo';
import LandingBanner from '../components/LandingBanner/LandingBanner';

const ImpressumPage = () => {
  return (
    <CookieLayout>
      <SEO title="Impressum" />
      <LandingBanner title="Impressum" alt />
      <section className="internal-page__section">
        <p>
          <b>W&amp;W Asset Management Ireland DAC</b>
          <br />
          Floor 7,
          <br />
          5 Earlsfort Terrace,
          <br />
          Dublin 2, <br />
          D02 CK83,
          <br />
          Ireland.
        </p>
        <p>
          <b>Company Number</b>
          <br />
          360156
        </p>
        <p>
          <b>Jurisdiction</b>
          <br />
          Ireland
        </p>
        <p>
          <b>Supervisory Authority</b>
          <br />
          Central Bank of Ireland
        </p>
        <p>
          <b>Parent</b>
          <br />
          W&amp;W Asset Management GmbH
        </p>
      </section>
    </CookieLayout>
  );
};

export default ImpressumPage;
